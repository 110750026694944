import { Page } from "../../common/page/Page";

function Resources() {
  return (
    <div className="About">
      <Page contentMain={<p>Lorem ipsum dolor sit amet</p>} />
    </div>
  );
}

export default Resources;
